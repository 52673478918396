import axios from "axios";
import { toast } from "react-toastify";

export const getTransactionAPI = async (query) => {
  try {
    const { userEmail, limit, page, searchValue, startDate, endDate } = query;
    console.log("Email : ", userEmail);
    const url = "subscription/transactions";

    const response = await axios.get(url, {
      params: {
        limit,
        page,
        searchValue,
        userEmail,
        "createTime[gte]": startDate,
        "createTime[lte]": endDate,
      },
    });
    return response?.data;
  } catch (error) {
    console.log("Errrrrrrrrrrrrrrrrrrrrr==", error);
    toast.error(error?.message);
    return error;
  }
};

export const getPayPalAccessToken = async (query) => {
  try {
    const response = await axios.get("/subscription/getPayPalToken");
    return response?.data;
  } catch (error) {
    console.log("Errrrrrrrrrrrrrrrrrrrrr==", error);
    toast.error(error?.message);
    return error;
  }
};

export const getChildUsersAPI = async (query) => {
  try {
    const { userEmail, limit, page, searchValue, startDate, endDate } = query;
    console.log("Email : ", userEmail);
    const url = `/childUsers/${userEmail}`;
    const response = await axios.get(url, {
      params: {
        limit,
        page,
        searchValue,
        "createTime[gte]": startDate,
        "createTime[lte]": endDate,
      },
    });
    return response?.data;
  } catch (error) {
    console.log("Errrrrrrrrrrrrrrrrrrrrr==", error);
    toast.error(error?.message);
    return error;
  }
};
