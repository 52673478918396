import React, { useEffect, useState } from "react";
import "./childList.css";
import AdminNav from "../AdminNav";
import Footer from "../../Footer";
import { Table } from "react-bootstrap";
import Loader from "../../loader/Loader";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";

import { HEAD_BG_COLOR } from "../../../constant/constant";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { getChildUsersAPI } from "../../../API/transaction";
import useFetch from "../../../hook/useFetch";
import useDebounce from "../../../hook/useDebounce";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: HEAD_BG_COLOR,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  TBL_ROW_THEME: {
    backgroundColor: theme.palette.action.hover,
  },
  STYLE_TBL_ROW_THEME: {
    border: 0,
  },
}));

const ChiildList = () => {
  const [isLoader, setisLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currnetPage, setCurrentPage] = useState(0);
  const [seachValue, setSeachValue] = useState("");
  const [rowPerPage, setRowPerPage] = useState(5);
  const [childUsersData, setChildUsersData] = useState([]);
  const debouncedSearchTerm = useDebounce(seachValue, 500);

  const { email } = useParams();

  const hanldeRowPerPage = (event) => {
    const { value } = event.target;
    setRowPerPage(Number(value));
  };

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected);
  };

  const fetchChildUsersData = async () => {
    setisLoader(true);
    try {
      const query = {
        limit: rowPerPage,
        page: currnetPage + 1,
        searchValue: seachValue,
        userEmail: email,
      };

      const response = await getChildUsersAPI(query);

      if (!response?.status === "success") {
        toast.error("something went wrong!");
        throw new Error("error.error_description");
      }

      setPageCount(response?.pagination?.totalPages);
      setChildUsersData(response?.data);
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      console.log(error);
      toast.error(error.toString().slice(error.toString().indexOf(":") + 1));
    }
  };

  const searchFirst = (e) => {
    let searchVal = e.target.value.toLowerCase();
    setSeachValue(searchVal);
  };

  useEffect(() => {
    fetchChildUsersData();
  }, [debouncedSearchTerm, currnetPage, rowPerPage]);

  return (
    <div>
      {isLoader && <Loader />}
      <AdminNav />
      <div className="main-content-gap">
        <div className="container">
          <div>
            <h2 className="text-center topHeading ">Group Member's List</h2>
            <div className="mb-4 d-flex justify-content-between ">
              <input
                name="Search"
                type="text"
                id="Search"
                placeholder="Type to search..."
                className="txt-wi"
                value={seachValue}
                onChange={searchFirst}
              />
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No</StyledTableCell>
                    <StyledTableCell align="center">First Name</StyledTableCell>
                    <StyledTableCell align="center">Last Name</StyledTableCell>
                    <StyledTableCell align="center">Username</StyledTableCell>
                    <StyledTableCell align="center">
                      Subscription Status
                    </StyledTableCell>
                    <StyledTableCell align="center">isActive</StyledTableCell>
                    <StyledTableCell align="center">CreatedAt</StyledTableCell>
                    <StyledTableCell align="center">UpdateAt</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childUsersData?.map((item, i) => (
                    <StyledTableRow key={i + "v125"}>
                      <StyledTableCell component="th" scope="row">
                        {i + currnetPage * rowPerPage + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item?.Fname}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item?.Lname}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item?.Username}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item?.SubscriptionStatus === "ACTIVE"
                          ? "Active"
                          : "Inactive"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item?.isActive + ""}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {dayjs(item?.Created_Date).format("MM/DD/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {dayjs(item?.Updated_Date).format("MM/DD/YYYY")}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-4 d-flex justify-content-end">
              <select
                className="selectCstm me-2 "
                value={rowPerPage}
                onChange={hanldeRowPerPage}
              >
                {[5, 10, 20, 25, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                previousLabel="< previous"
                nextLabel="next >"
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChiildList;
