import React from "react";
import { Button, Modal, Table } from "react-bootstrap";

const UpgradePlanModal = ({
  handleClose,
  upgradeShow,
  priceDetails,
  updateOrUpgradeSubscriptionHandler,
  shippingAmount,
  isUpdate,
  isIndividual,
  MemberTyp,
  newSubType,
  updatedQty,
  isConfirmLoading,
}) => {
  const ratePerQuantity = priceDetails?.proratedData?.ratePerQuantity;
  const totalProratedAmount = priceDetails?.proratedData?.totalProratedAmount;
  const quantityBeforeUpdate = localStorage.getItem("memberQuantity");
  let upcomingQuantityAmount = 0;
  let currentQuantityAmount = 0;

  let currentlyUsedQuantity;

  const dateFormatFunction = (date) => {
    const dateObject = new Date(date);
    // Extract the parts
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = dateObject.getUTCFullYear();
    // Format the parts into the desired format
    const formattedStartDate = `${month}/${day}/${year}`;
    return formattedStartDate;
  };

  return (
    <div>
      <Modal
        show={upgradeShow}
        onHide={handleClose}
        centered
        className="upgrade-plan-modal modal-xl"
      >
        <Modal.Header>
          <Modal.Title
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Upgrade Subscription Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="upgrade-plan-modal-body ">
          {newSubType !== "yearly" && (
            <Table bordered className="price-info-table">
              <thead
                style={{ background: "rgb(5 132 206)" }}
                className="price-info"
              >
                <tr>
                  <td>Plan</td>
                  <td>Start Date</td>
                  <td>Next Due Date</td>
                  <td>Subscription Days (D)</td>
                  <td>
                    Current Qty(CQ)<span> (paid)</span>
                  </td>
                  <td>New Added Qty(AQ)</td>
                  <td>Total Qty (CQ+AQ)</td>
                  <td>Amount</td>
                  <td>Rate per quantity</td>
                  <td>Payment Status</td>
                </tr>
              </thead>
              <tbody>
                {priceDetails?.proratedData?.extraQuantities?.map(
                  (val, index) => {
                    const {
                      startDate,
                      nextDueDate,
                      days,
                      extraQuantity,
                      Amount,
                      currentlyUsed,
                      isPaid,
                      currentQuantity,
                      subscriptionType,
                    } = val;

                    if (currentQuantity === 0 && isPaid === false) {
                      upcomingQuantityAmount = Amount;
                    }

                    if (currentlyUsed === true) {
                      currentlyUsedQuantity = val;
                      currentQuantityAmount = Amount;
                    }

                    return (
                      <tr key={index}>
                        <td>
                          <div>
                            <span>
                              {currentlyUsed ? "Current plan" : "Upcoming Plan"}
                              <span className="nowrap">
                                (
                                {subscriptionType === "monthly" ||
                                subscriptionType === "yearly"
                                  ? `Individual ${subscriptionType}`
                                  : subscriptionType}
                                )
                              </span>
                            </span>
                          </div>
                        </td>
                        <td>{dateFormatFunction(startDate)}</td>
                        <td>{dateFormatFunction(nextDueDate)}</td>
                        <td>{days}</td>
                        <td>{currentQuantity}</td>
                        <td>{extraQuantity}</td>
                        <td>
                          {updatedQty === "undefined"
                            ? 3
                            : parseInt(currentQuantity) +
                              parseInt(extraQuantity)}
                        </td>
                        <td>{`$${Amount}`}</td>
                        <td>{`$${ratePerQuantity}`}</td>
                        <td className={isPaid ? "paid" : "unpaid"}>
                          {isPaid ? "Paid" : "Unpaid"}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          )}
          {newSubType !== "yearly" && (
            <div>
              <h5>
                &#x2022; Your current quantity is ={" "}
                {quantityBeforeUpdate !== "undefined"
                  ? quantityBeforeUpdate
                  : 1}{" "}
              </h5>

              {currentlyUsedQuantity?.extraQuantity ? (
                <h5>
                  &#x2022; Total amount for{" "}
                  <strong>{currentlyUsedQuantity?.extraQuantity}</strong> extra
                  quantities = ${parseFloat(currentQuantityAmount)?.toFixed(2)}{" "}
                </h5>
              ) : (
                ""
              )}

              <h5>
                &#x2022; Total amount of upcoming plans :{" "}
                {priceDetails?.proratedData?.extraQuantities?.map(
                  (quantity, index) => {
                    if (quantity?.currentlyUsed === false) {
                      if (parseFloat(quantity?.Amount) > 0) {
                        return (
                          <span key={Math.random()}>
                            {" "}
                            ${parseFloat(quantity?.Amount)?.toFixed(2)}{" "}
                            {priceDetails?.proratedData?.extraQuantities
                              ?.length -
                              1 ===
                            index
                              ? " "
                              : "+"}
                          </span>
                        );
                      }
                    }
                  }
                )}{" "}
                = $
                {(
                  parseFloat(totalProratedAmount) -
                  parseFloat(currentQuantityAmount) +
                  parseFloat(upcomingQuantityAmount)
                )?.toFixed(2)}
              </h5>
              <h5>
                &#x2022; Total amount : $
                {parseFloat(currentQuantityAmount)?.toFixed(2)}(current plan) +{" "}
                $
                {(
                  parseFloat(totalProratedAmount) -
                  parseFloat(currentQuantityAmount) +
                  parseFloat(upcomingQuantityAmount)
                )?.toFixed(2)}
                (upcoming $
                {priceDetails?.proratedData?.extraQuantities?.length > 2
                  ? "plans"
                  : "plan"}
                ) = $
                {(
                  parseFloat(totalProratedAmount) -
                  parseFloat(currentQuantityAmount) +
                  parseFloat(upcomingQuantityAmount) +
                  parseFloat(currentQuantityAmount)
                )?.toFixed(2)}
              </h5>
              <strong>
                {" "}
                <p className="mt-1">Important Note : </p>
              </strong>
              <p>
                <strong>
                  {" "}
                  &#x2022; Total billing cost is getting calculated based on New
                  Added Quantity of current and upcoming subscription cycle.
                </strong>
              </p>

              <p>
                <strong>
                  {" "}
                  &#x2022; We will charge the extra quantities through shipping
                  amount on already existing subscription plans.
                </strong>
              </p>
              <p>
                <table className="w-100" style={{ color: "#0584ce" }}>
                  <tr>
                    <td>
                      <p className="h4">Extra Quantities =</p>
                    </td>
                    <td>
                      <span style={{ textAlign: "center" }}>
                        <p className="h4" style={{ marginBottom: "0" }}>
                          per unit cost ($x)
                        </p>
                        <p
                          className="h4"
                          style={{ borderTop: "3px solid", marginBottom: "0" }}
                        >
                          billing duration (x days)
                        </p>
                      </span>
                    </td>
                    <td className="pt-2">
                      {" "}
                      <p className="h4">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </span>{" "}
                        quantity (x){" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </span>{" "}
                        number of days (x)
                      </p>
                    </td>
                  </tr>
                </table>
              </p>
            </div>
          )}
          {newSubType === "yearly" && (
            <div>
              <Table bordered className="price-info-table">
                <thead
                  style={{ background: "rgb(5 132 206)" }}
                  className="price-info"
                >
                  <tr>
                    <td>Plan</td>
                    <td>Start Date</td>
                    <td>Next Due Date</td>
                    <td>Subscription Days (D)</td>
                    <td>
                      Current Qty(CQ) <span>(paid)</span>
                    </td>
                    <td>New Added Qty(AQ)</td>
                    <td>Total Qty (CQ+AQ)</td>
                    <td>Amount</td>
                    <td>Rate per quantity</td>
                    <td>Payment Status</td>
                  </tr>
                </thead>
                <tbody>
                  {priceDetails?.proratedData?.extraQuantities?.map(
                    (val, index) => {
                      const {
                        startDate,
                        nextDueDate,
                        days,
                        extraQuantity,
                        Amount,
                        currentlyUsed,
                        isPaid,
                        lastUpdated,
                        currentQuantity,
                        subscriptionType,
                      } = val;

                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            <div>
                              <span>
                                {currentlyUsed
                                  ? "Current plan"
                                  : "Upcoming Plan"}
                              </span>
                              <span className="mx-1">({subscriptionType})</span>
                            </div>
                          </td>
                          <td>{dateFormatFunction(startDate)}</td>
                          <td>{dateFormatFunction(nextDueDate)}</td>
                          <td>{days}</td>
                          <td>{lastUpdated ? 0 : 1}</td>
                          <td>{currentQuantity}</td>
                          <td>{1}</td>
                          <td>{`$${isPaid ? 0 : Amount}`}</td>
                          <td>{`$${
                            currentlyUsed ? Amount : ratePerQuantity
                          }`}</td>
                          <td className={isPaid ? "paid" : "unpaid"}>
                            {isPaid ? "Paid" : "Unpaid"}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="model-footer-new"
          style={{ height: "4.25rem" }}
        >
          <Button
            className="confirm-btns add-member"
            style={{
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "600",
            }}
            variant="success"
            disabled={isConfirmLoading}
            onClick={() => {
              updateOrUpgradeSubscriptionHandler(
                shippingAmount,
                isUpdate,
                isIndividual,
                MemberTyp,
                newSubType
              );
            }}
          >
            Confirm
          </Button>
          <Button
            className="cancle-buttons"
            variant="danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpgradePlanModal;
