import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

import { BASEURL } from "../../constant/constant";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Loader from "../loader/Loader";
import axios from "axios";
import { DevTool } from "@hookform/devtools";
import { useNavigate } from "react-router-dom";
import helper from "../../helper/authHelper";

const ManageGroup = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
    control,
  } = useForm();

  const [isLoader, setisLoader] = useState(false);
  const [pageEvent, setPageEvent] = useState(null);
  const [show, setShow] = useState(false);
  const [removeShow, setRemoveShow] = useState(false);
  const [childUsername, setChildUsername] = useState();
  const [search, setSearch] = useState("");
  const [updateFormTitle, setUpdateFormTitle] = useState(null);

  const handleClose = () => {
    setShow(false);
    setValue("id", "");
    setValue("Fname", "");
    setValue("Lname", "");
    setValue("Username", "");
    setUpdateFormTitle(null);
  };
  const handleRemoveClose = () => setRemoveShow(false);

  const addUserHandler = () => {
    setShow(true);
  };

  const updateUserHandler = (data, edit) => {
    setValue("id", data._id);
    setValue("Fname", data.Fname);
    setValue("Lname", data.Lname);
    setValue("Username", data.Username);
    setShow(true);
    setUpdateFormTitle(edit);
  };

  const useEmail = JSON.parse(localStorage.getItem("useEmail"));
  const fname = JSON.parse(localStorage.getItem("fname"));
  const subscriptionStatus = localStorage.getItem("MemberStatus");
  const MemberType = localStorage.getItem("MemberType");
  let memberQuantity;
  if (MemberType == "Group monthly" || MemberType == "Group Yearly") {
    memberQuantity = JSON.parse(localStorage.getItem("memberQuantity"));
  }
  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [page, setPage] = useState(0);
  let itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = userList?.slice(itemOffset, endOffset);
  const [currList, setCurrList] = useState(currentItems);
  const [pageCount, setPageCount] = useState(0);
  const [isActive, setIsActive] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrList(userList);
  }, [userList]);
  // page change functionality
  const handlePageClick = (event) => {
    setPageEvent(event);
    setisLoader(true);
    if (event === null) {
      setItemOffset(0);
    } else {
      const newOffset =
        ((event.selected * itemsPerPage) % userList?.length) * pageCount;
      setItemOffset(newOffset);
      setPage(event.selected);
      getMembers(event.selected, search);
    }
    // setisLoader(false);
  };

  const onSubmit = async (data) => {
    handlePageClick(pageEvent);
    if (data.id.length > 0) {
      updateChildMember(data, page, search);
    } else {
      handlePageClick(pageEvent);
      const headers = {
        "Content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("LogUser")),
      };
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          Fname: data?.Fname,
          Lname: data?.Lname,
          Username: data?.Username,
          Password: data?.Password,
          SubscriptionStatus: "ACTIVE",
          parentEmail: useEmail,
          childUpdate: true,
          userType: "web",
        }),
      };
      const ApiRes = await fetch(`${BASEURL}/user/addmember`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((err) => {
          console.log("err", err);
          reset();
        });

      if (ApiRes?.status === 401) {
        toast.error(ApiRes?.message);
        helper(navigate, setisLoader);
        reset();
      }

      if (ApiRes?.Status === 200) {
        handlePageClick(pageEvent);
        toast.success(ApiRes.message);
        setShow(false);
        reset();
        getMembers(page, search);
        getMembers1();
      } else {
        reset();
        toast.error(ApiRes?.message ? ApiRes?.message : ApiRes?.Error);
      }
    }

    setisLoader(false);
  };

  const getAllMembers = async () => {
    axios
      .post(`${BASEURL}/user/getGroupMember`, { parentEmail: useEmail })
      .then((response) => {
        console.log("140", response.data);
        // console.log(response.parentEmail: 'example@example.com');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Username = JSON.parse(localStorage.getItem("Username"));
  const getMembers = async (page, search) => {
    let allUserData = await fetch(
      `${BASEURL}/user/getGroupMemberPagin?page=${page}&parentEmail=${useEmail}&search=${search}&Username=${Username}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(localStorage.getItem("LogUser")),
        },
      }
    );
    allUserData = await allUserData.json();
    // setisSearchLoader(false);
    if (allUserData != undefined) {
      const allUsers = allUserData?.Info;
      setPageCount(allUserData?.totalPage);
      setCurrList([]);
      setCurrList(allUsers);
      // setFilUserList(allUsers);
      // setisLoader(false);
    }

    if (allUserData?.status === 401) {
      toast.error(allUserData?.message);
      helper(navigate, setisLoader);
    }
    setisLoader(false);
  };
  const getMembers1 = async () => {
    let allUserData = await fetch(
      `${BASEURL}/user/getGroupMemberPagin?page=0&parentEmail=${useEmail}`
    );
    allUserData = await allUserData.json();
    // setisSearchLoader(false);
    if (allUserData != undefined) {
      const allUsers = allUserData?.Info;
      const allUsersCount = allUserData?.length;
      setPageCount(allUserData?.totalPage);
      setUserList([]);
      setUserList(allUsers);
      setUserCount(0);
      setUserCount(allUsersCount);
      // setFilUserList(allUsers);
      // setisLoader(false);
    }
  };

  useEffect(() => {
    getMembers(page, search);
    getAllMembers();
    getMembers1();
  }, []);

  const removeChildMember = async ({ Username }) => {
    setisLoader(true);
    handlePageClick(pageEvent);
    const headers = {
      "Content-Type": "application/json",
      authorization: JSON.parse(localStorage.getItem("LogUser")),
    };
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        Username: Username,
        childUpdate: true,
      }),
    };
    const ApiRes = await fetch(`${BASEURL}/childuser/delete`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // setChildData(data);
        return data;
      });

    if (ApiRes?.status === 401) {
      toast.error(ApiRes?.message);
      helper(navigate, setisLoader);
    }

    if (ApiRes?.Status === 200) {
      handlePageClick(pageEvent);
      toast.success(ApiRes.message);
      setTimeout(() => {
        setisLoader(false);
      }, 1000);
      getMembers(page, search);
      getMembers1();
    } else {
      toast.error(ApiRes.message);
    }
  };

  const updateChildMember = async (data, page, search) => {
    setisLoader(true);
    handlePageClick(pageEvent);
    const headers = {
      "Content-Type": "application/json",
      authorization: JSON.parse(localStorage.getItem("LogUser")),
    };
    const dataa = JSON.parse(localStorage.getItem("LogUser"));
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        _id: data?.id,
        Fname: data?.Fname,
        Lname: data?.Lname,
        Username: data?.Username,
        Password: data?.Password,
        childUpdate: true,
      }),
    };
    const ApiRes = await fetch(`${BASEURL}/childuser/update`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // setChildData(data);
        return data;
      });

    if (ApiRes?.status === 401) {
      toast.error(ApiRes?.message);
      helper(navigate, setisLoader);
    }

    if (ApiRes?.status === 200) {
      handlePageClick(pageEvent);
      setisLoader(false);
      toast.success(ApiRes.message);
      setShow(false);
      reset();
      setTimeout(() => {
        setisLoader(false);
      }, 2000);
      getMembers(page, search);
      setUpdateFormTitle(null);
    } else {
      toast.error(ApiRes?.message ? ApiRes?.message : ApiRes?.Error);
    }
  };

  const toggle = async (id, isActive, parentEmail) => {
    setisLoader(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, isActive, parentEmail }),
    };

    const response = await fetch(
      `${BASEURL}/subscription/activeOrInactiveChild`,
      requestOptions
    );

    const result = await response.json();

    if (response.status == 200) {
      setIsActive(!isActive);

      getMembers(page, search);
      getMembers1();

      toast.success(result.message);
    } else {
      // toast.success(USER_BLOCK);
      toast.error(result.message);
    }
    setisLoader(false);
  };

  const [timerId, setTimerId] = useState();
  const searchHandler = (e) => {
    let searchVal = e.target.value.toLowerCase();
    setSearch(e.target.value);

    if (timerId) {
      clearTimeout(timerId);
    }

    if (e.target.value !== "") {
      setTimerId(
        setTimeout(() => {
          getMembers(0, searchVal);
          setTimerId(null);
        }, 750) // 750ms delay
      );
    } else {
      getMembers(page, searchVal);
    }
  };

  const MemberTyp = localStorage.getItem("MemberType");

  // console.log("userList?.length",userList?.length,memberQuantity)

  return (
    <>
      {isLoader && <Loader />}
      <div style={{ position: "relative" }}>
        <Navbar MemberType={MemberTyp} />
        <div className="main-content-gap">
          <div className="container">
            <Row>
              <Col className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "2rem" }}>
                    <input
                      className="child-search"
                      type="text"
                      placeholder="Search..."
                      onChange={searchHandler}
                    />
                  </div>
                  <div>
                    {/* {subscriptionStatus === "EMPTY" ? null : <button disabled={(userList?.length < memberQuantity) === true ? false : true} className="btn add-member" onClick={addUserHandler}>Add Member +</button>} */}
                    {subscriptionStatus === "EMPTY" ? null : (
                      <button
                        className="btn add-member"
                        onClick={addUserHandler}
                      >
                        Add Member +
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col md={12}>
                <div className="card-main mb-0">
                  <div className="card-head">
                    <span className="head-card-img">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z" />
                      </svg>
                    </span>

                    <h4 className="head-card-text">Online profile</h4>
                  </div>
                  <div className="body-card formSection form-custm-group">
                    <div className="card-inner-p">
                      <Row>
                        <Col md={2}>
                          <div className="d-flex gap-4">
                            <h4 className="card-body-head">Owner</h4>
                            <div className="cb-head-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="currentColor"
                                  d="M28 12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h11v4H9a2 2 0 0 0-2 2v4H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H9v-4h14v4h-3a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-3v-4a2 2 0 0 0-2-2h-6v-4ZM12 28H4v-4h8Zm16 0h-8v-4h8ZM4 4h24v6H4Z"
                                />
                              </svg>
                            </div>
                          </div>
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={6} className="">
                              <div className="inputBox position-relative">
                                <label htmlFor="" className="form-label">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={fname}
                                  aria-describedby=""
                                  readOnly
                                />
                              </div>
                            </Col>
                            <Col md={6} className="">
                              <div className="inputBox position-relative">
                                <label htmlFor="" className="form-label">
                                  Email
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={useEmail}
                                  aria-describedby="emaillHelp"
                                  readOnly
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="tbl_cstm-manage-grp">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="col-1">
                          Sub Members
                        </th>
                        <th scope="col" className="col-1">
                          Name
                        </th>
                        <th scope="col" className="col-1">
                          Username
                        </th>
                        <th scope="col" className="col-1">
                          Status
                        </th>
                        <th scope="col" className="col-1">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currList?.map((child, index) => (
                        <tr key={index}>
                          <td>Child {index + page * 5 + 1}</td>
                          <td>
                            {child?.Fname} {child?.Lname}
                          </td>
                          <td>{child?.Username}</td>
                          <td>
                            {" "}
                            <>
                              <FormControlLabel
                                control={<Switch checked={child?.isActive} />}
                                label={child.isActive ? "Active" : "Inactive"}
                                onChange={() => {
                                  toggle(
                                    child._id,
                                    !child.isActive,
                                    child.parentEmail
                                  );
                                }}
                              />
                            </>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <button
                                className="btn add-member"
                                onClick={() => updateUserHandler(child, "Edit")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                </svg>
                              </button>
                              <button
                                style={{ marginLeft: "1rem" }}
                                className="btn add-member"
                                onClick={() => {
                                  setRemoveShow(true);
                                  setChildUsername(child);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="pagin">
                  <ReactPaginate
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    previousLabel="< previous"
                    nextLabel="next >"
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {updateFormTitle === "Edit" ? (
              <span>Update Member</span>
            ) : (
              <span>Add new Member</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-4 new-class">
                <Col className="col-md-6 first-name">
                  <div className="main-input-class">
                    <div className="label-names">
                      {" "}
                      <label>First Name</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="d-none"
                        {...register("id")}
                      />
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          border: "1px solid gray",
                          padding: "4px 10px",
                          borderRadius: "4px",
                          fontFamily: "sans-serif",
                        }}
                        {...register("Fname", {
                          required: true,
                          pattern: /.*[a-zA-Z].*/,
                        })}
                      />
                    </div>
                  </div>
                  {/* {errors.Fname && <p className="text-danger" style={{ fontSize: "16px", fontFamily: "sans-serif", marginTop: "6px" }}>First name is required.</p>} */}
                  {errors.Fname && errors.Fname.type === "required" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      First name is required.
                    </p>
                  )}
                  {errors.Fname && errors.Fname.type === "pattern" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      First name must contain an alphabet.
                    </p>
                  )}
                </Col>
                <Col className="col-md-6">
                  <div className="main-input-class">
                    <div className="label-names">
                      {" "}
                      <label>Last Name</label>
                    </div>
                    <div>
                      {" "}
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          border: "1px solid gray",
                          padding: "4px 10px",
                          borderRadius: "4px",
                          fontFamily: "sans-serif",
                        }}
                        {...register("Lname", {
                          required: true,
                          pattern: /.*[a-zA-Z].*/,
                        })}
                      />
                    </div>
                  </div>
                  {/* {errors.Lname && <p className="text-danger" style={{ fontSize: "16px", fontFamily: "sans-serif", marginTop: "6px" }}>Last name is required.</p>} */}
                  {errors.Lname && errors.Lname.type === "required" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      Last name is required.
                    </p>
                  )}
                  {errors.Lname && errors.Lname.type === "pattern" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      Last name must contain an alphabet.
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="col-md-6">
                  <div className="main-input-class">
                    <div className="label-names">
                      {" "}
                      <label>Username</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          border: "1px solid gray",
                          padding: "4px 10px",
                          borderRadius: "4px",
                          fontFamily: "sans-serif",
                        }}
                        autoComplete="new-password"
                        {...register("Username", {
                          required: true,
                          pattern: /^[a-zA-Z0-9_]{3,}$/,
                        })}
                      />
                    </div>
                  </div>
                  {/* {errors.Username && <p className="text-danger" style={{ fontSize: "16px", fontFamily: "sans-serif", marginTop: "6px" }}>Username is required.</p>} */}
                  {errors.Username && errors.Username.type === "required" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      Username is required.
                    </p>
                  )}
                  {errors.Username && errors.Username.type === "pattern" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      Username must contain an alphabet and minimum 3 characters
                    </p>
                  )}
                </Col>
                <Col className="col-md-6">
                  <div className="main-input-class">
                    <div className="label-names">
                      {" "}
                      <label>Password</label>
                    </div>
                    <div>
                      <input
                        type="password"
                        minLength={3}
                        style={{
                          width: "100%",
                          border: "1px solid gray",
                          padding: "4px 10px",
                          borderRadius: "4px",
                          fontFamily: "sans-serif",
                        }}
                        autoComplete="new-password"
                        {...register("Password", {
                          required: updateFormTitle === "Edit" ? false : true,
                          pattern: /.{3,16}/,
                        })}
                      />
                    </div>
                  </div>
                  {errors.Password && errors.Password.type === "required" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      Password is required.
                    </p>
                  )}
                  {/* {errors.Username && errors.Username.type === "required" && <p className="text-danger" style={{ fontSize: "16px", fontFamily: "sans-serif", marginTop: "6px" }}>Username is required.</p>} */}
                  {errors.Password && errors.Password.type === "pattern" && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        marginTop: "6px",
                      }}
                    >
                      Password must have atleast 3 characters.
                    </p>
                  )}
                </Col>
              </Row>
              <Col style={{ textAlign: "right" }}>
                <button
                  type="submit"
                  className="btn add-member"
                  disabled={isLoader}
                >
                  {updateFormTitle === "Edit" ? "Update" : "Submit"}
                </button>
              </Col>
            </form>
            <DevTool control={control} />
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ height: "3.25rem" }}></Modal.Footer>
      </Modal>
      <Modal show={removeShow} onHide={handleRemoveClose} centered>
        <Modal.Header className="model-head-new" closeButton>
          <Modal.Title
            style={{
              fontFamily: "sans-serif",
              fontSize: "22px",
              fontWeight: "400",
            }}
          >
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="model-body-inner-sec">
            <span className="warning-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
              </svg>
            </span>
            <span className="content-text">
              Are you sure wants to delete member?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="model-footer-new">
          <Button
            className="confirm-btns add-member"
            style={{
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "600",
            }}
            variant="success"
            onClick={() => {
              removeChildMember(childUsername);
              handleRemoveClose();
            }}
          >
            Delete
          </Button>
          <Button
            className="cancle-buttons"
            variant="danger"
            onClick={handleRemoveClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageGroup;
