import { createTheme } from "@mui/material/styles";

// Define your custom theme
export const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          // Modal background styling
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Customize backdrop
          },
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: 8,
          right: 8,
          color: "grey.500",
        },
      },
    },
  },
});
