import React, { useEffect, useState } from "react";
import Loader from "../loader/Loader.jsx";
import { toast } from "react-toastify";
// import ExtensionCard from "../UserDashboard/ExtensionCard/ExtensionCard.jsx";
import ExtensionCard from "../ExtensionCard/ExtensionCard.jsx";

const ManageExtension = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [isExtEnabled, setIsExtEnabled] = useState(false);

  const extensionID = process.env.REACT_APP_EXTENSION_ID;

  console.log("EXT ===================== : ", extensionID);

  // Listen for messages from the content script
  const handleMessage = (event) => {
    console.log("EEEEEEEEEEE : ", event);

    if (event.data.extensionInfo === "ExtensionIsDisabled") {
      setIsExtEnabled(false);
      // return toast.error(
      //   "Please enable or install the extension and refresh the page"
      // );
    }

    if (
      event.data.type === "EXTENSION_INFO_RESPONSE" &&
      event.data?.extensionInfo
    ) {
      setIsDisable(event.data?.extensionInfo?.enabled);
      setTimeout(() => {
        setisLoader(false);
      }, 1000);
    } else if (event.data.type === "TOGGLE_EXTENSION") {
      setIsDisable((prev) => !prev);
      setTimeout(() => {
        setisLoader(false);
      }, 1000);
    }
  };

  const handleExtensionState = (event) => {
    if (
      event.data.type === "EXTENSION_INFO_RESPONSE" &&
      event.data?.extensionInfo !== "ExtensionIsDisabled"
    ) {
      setIsExtEnabled(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // Send the message after adding the event listener
    window.postMessage(
      { type: "GET_EXTENSION_INFO", id: process.env.REACT_APP_EXTENSION_ID },
      "*"
    );

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [isDisable]);

  useEffect(() => {
    window.addEventListener("message", handleExtensionState);

    // Send the message after adding the event listener
    window.postMessage(
      { type: "GET_EXTENSION_INFO", id: process.env.REACT_APP_EXTENSION_ID },
      "*"
    );

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleExtensionState);
    };
  }, []);

  const handleToggle = () => {
    setisLoader(true);
    window.postMessage({ type: "TOGGLE_EXTENSION" }, "*");
  };

  return (
    <div>
      {isLoader && <Loader />}

      {isExtEnabled ? (
        <>
          {/* <button
            className="btn btn-primary dwn"
            style={{ marginLeft: "0.6rem" }}
            onClick={handleToggle}
          >
            {console.log("VVVVVVVVVVVVVVVVVVVVVVVVVV : ", isDisable)}
            {isLoader
              ? !isDisable
                ? "Active"
                : "Inactive"
              : isDisable
              ? "Active"
              : "Inactive"}
          </button> */}
          {/* <div> */}
          {/* <Tooltip
            title={
              isLoader
                ? !isDisable
                  ? "Active"
                  : "Inactive"
                : isDisable
                ? "Active"
                : "Inactive"
            }
          >
            <FormControlLabel
              control={<Switch checked={isDisable === true ? true : false} />}
              onChange={handleToggle}
            />
          </Tooltip> */}
          {/* </div> */}
        </>
      ) : (
        <>
          {/* <button
            className="btn btn-primary dwn"
            style={{ marginLeft: "0.6rem" }}
            onClick={handleToggle}
            disabled={true}
          > */}
          {/* {isLoader
            ? !isDisable
              ? "Activate Readmax Extension"
              : "Deactivate Readmax Extension"
            : isDisable
            ? "Activate Readmax Extension"
            : "Deactivate Readmax Extension"} */}
          {/* {isLoader
            ? "Activate Readmax Extension"
            : "Deactivate Readmax Extension"} */}
          {/* </button> */}
        </>
      )}

      <div>
        <ExtensionCard
          handleToggle={handleToggle}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          isExtEnabled={isExtEnabled}
        />
      </div>
    </div>
  );
};

export default ManageExtension;
