import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import "./Load.css";
// import CircularProgress from "@mui/material/CircularProgress";
export default function SimpleBackdrop() {
  return (
    <div>
      <Backdrop
        sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        {/* <CircularProgress color="inherit" /> */}
        <div id="loading-spinner">
          <div className="logo">Rm</div>
          <div className="spinner outer">
            <div className="spinner inner">
              <div className="spinner eye"></div>
            </div>
          </div>
        </div>{" "}
      </Backdrop>
    </div>
  );
}
