import React, { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Loader from "../Components/loader/Loader";
import ConnectWithUs from "../Components/UserDashboard/ConnectWithUs/ConnectWithUs";

const Dashboard = lazy(() => import("../Components/Dashboard"));
const UserProfile = lazy(() => import("../Components/UserProfile"));
const Privacypolicy = lazy(() => import("../Components/Privacypolicy"));
const Setting = lazy(() => import("../Components/Setting"));
const Information = lazy(() =>
  import("../Components/UserDashboard/Information")
);
const Testimonials = lazy(() =>
  import("../Components/UserDashboard/Testimonials")
);
const Research = lazy(() => import("../Components/UserDashboard/Research"));
const UserSubscription = lazy(() =>
  import("../Components/UserDashboard/UserSubscription")
);
const ContactUs = lazy(() => import("../Components/UserDashboard/ContactUs"));
const Awards = lazy(() => import("../Components/UserDashboard/Awards"));
const ManageGroup = lazy(() =>
  import("../Components/UserDashboard/ManageGroup")
);
const UpgradeSubscription = lazy(() =>
  import("../Components/UserDashboard/UpgradeSubscription")
);
const PageNotFound = lazy(() => import("../Components/PageNotFound"));

const NonAdminRoutes = () => {
  const navigate = useNavigate();
  const [access, setAccess] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("role");
    setAccess(role);
  }, []);

  if (access !== "true") {
    return (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="user/dashboard" element={<Dashboard />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/user/testimonial" element={<Testimonials />} />
          <Route path="/user/setting" element={<Setting />} />
          <Route path="/research/information" element={<Information />} />
          <Route path="/user/research" element={<Research />} />
          <Route path="/user/subscription" element={<UserSubscription />} />
          <Route path="/user/managegroup" element={<ManageGroup />} />
          <Route path="/user/upgrade" element={<UpgradeSubscription />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/connect-with-us" element={<ConnectWithUs />} />
          <Route path="/liveInk/awards" element={<Awards />} />
          <Route path="/user/profile" element={<UserProfile />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    );
  }

  return <Navigate to="/admin" replace />;
};

export default NonAdminRoutes;
