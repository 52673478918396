import React, { useEffect, useState } from "react";
import {
  Facebook,
  LinkedIn,
  YouTube,
  Google,
  Instagram,
} from "@mui/icons-material";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import XIcon from "@mui/icons-material/X";

import { apiCall } from "../../../API/apiCall";
import { PUBLIC_URL } from "../../../constant/constant";

import {
  AppStore,
  GooglePlay,
} from "../../../Components/constants/DownloadAppButtons";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";

import "./connectwithus.css";

const MemberTyp = localStorage.getItem("MemberType");

const ConnectWithUs = () => {
  const [isLoader, setisLoader] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setisLoader(true);
        const response = await apiCall({
          url: "/social-media/",
          method: "GET",
        });
        console.log("Data : ", response);
        setLinks(response?.data);
        setisLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div className="connect-container cstm-social-box">
        <div className="blurred-background">
          <div className="connet-with-wrapper">
            <h4 className="connect-heading">Connect with us on</h4>

            <div className="social-media-main-box">
              {links?.map((el, key) => (
                <div key={key} className="social-media-icon-box">
                  <a
                    href={el.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    <div className="social-card">
                      <div className="social-icon-box">
                        <img
                          className="icon-image-logo"
                          src={PUBLIC_URL + "../uploads/icons/" + el.icon}
                        />
                      </div>

                      <p variant="h5" className="social-text">
                        {el.name}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="bottom-conatiner">
            <p variant="h2" className="connect-title-1">
              Download our App
            </p>
            <div className="footer-app-icon">
              <a
                href="https://apps.apple.com/us/app/readmax/id6502988625"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <AppStore />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.readmax&hl=en_IN"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <GooglePlay />
              </a>
            </div>
          </div>
        </div>
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default ConnectWithUs;
