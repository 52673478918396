export const ENV = "uat";

export const APPURL =
  ENV === "dev"
    ? process.env.REACT_APP_FRONTEND_BASE_URL_DEV
    : ENV === "qa"
    ? process.env.REACT_APP_FRONTEND_BASE_URL_QA
    : ENV === "uat"
    ? process.env.REACT_APP_FRONTEND_BASE_URL_UAT
    : ENV === "CHETU_UAT"
    ? process.env.REACT_APP_FRONTEND_BASE_URL_CHETU_UAT
    : process.env.REACT_APP_FRONTEND_BASE_URL_PROD;

export const BASEURL =
  ENV === "dev"
    ? process.env.REACT_APP_BACKEND_BASE_URL_DEV
    : ENV === "qa"
    ? process.env.REACT_APP_BACKEND_BASE_URL_QA
    : ENV === "uat"
    ? process.env.REACT_APP_BACKEND_BASE_URL_UAT
    : ENV === "CHETU_UAT"
    ? process.env.REACT_APP_BACKEND_BASE_URL_CHETU_UAT
    : process.env.REACT_APP_BACKEND_BASE_URL_PROD;

export const PUBLIC_URL =
  ENV === "dev"
    ? process.env.REACT_APP_BACKEND_PUBLIC_URL_DEV
    : ENV === "qa"
    ? process.env.REACT_APP_BACKEND_PUBLIC_URL_QA
    : ENV === "uat"
    ? process.env.REACT_APP_BACKEND_PUBLIC_URL_UAT
    : ENV === "CHETU_UAT"
    ? process.env.REACT_APP_BACKEND_PUBLIC_URL_CHETU_UAT
    : process.env.REACT_APP_BACKEND_PUBLIC_URL_PROD;

export const HEAD_BG_COLOR = "#0584ce";
export const TBL_ROW_THEME = "&:nth-of-type(odd)";
export const STYLE_TBL_ROW_THEME = "&:last-child td, &:last-child th";
export const ADMN_NAV_IMAGE =
  "/static/media/menu-left.b67bda26bd5b16de71eeef58af3376eb.svg";
export const ADMN_CONTACT_ERORR_FIRST_ADD = "First address is mandatory";
export const CONTACT_ERORR_MOBILE_NO = "Invalid mobile number";
export const CONTACT_ERORR_EMAIL = "Invalid email id";
export const CONTACT_UPDATED = "Contact updated";
export const CONTACT_DETAIL_SUCCESS_MSG =
  "Contact details have updated successfully";
export const CRED = "LogUser";
export const success = "success";
export const INFO_UPDT_MSG = "Updated";
export const NOT_UPDAT_MSG = "can't update";
export const INFO_CREATE_MSG = "created";
export const INFO_DELETE_MSG = "Item is Deleted";
export const TESTI1_DELETE_MSG = "Deleted";
export const ERORR_MSG = "error";
export const LOGIN_SUCCESS_MSG = "User Login successfully";
export const LOGIN_ERORR_MSG = "Invalid Credentials";
export const YOUTUBE_UPDATE_MSG = "URL updated";
export const USER_FOUND_DEL = "User Found & Deleted";
export const USER_FOUND_UNBLOCK = "User Found & unblocked";
export const ADMIN_ACCESS_ENABLE = "Admin right enable";
export const ADMIN_ACCESS_DESABLE = "Admin right removed";
export const USER_FOUND_BLOCK = "User Found & Blocked";
export const NOT_FOUND = "User Not Found";
export const USER_SUCCESS = "User Found";
export const UNVERIFIED_USER = "Email is not verified";
export const LOGIN_SUCCESS = "User Login Successfully";
export const REG_SUCCESS = "User Registerd Sucessfully";
export const LOGOUT_SUCCESS = "You have been successfully logged out…!";
export const EMAIL_EXIST = "Email Already Exist";
export const ACCOUNT_DELETED =
  "Your account has been deactivated. Please contact rwalker@liveink.com to reactivate your account.";
export const MOB_EXIST = "Mobile Number Already Exist";
export const MISS_FIELDS = "All fields must be required";
export const USER_BLOCK = "User Blocked";
export const USER_UNBLOCK = "User Unblocked";
export const OTP_SEND = "OTP sent successfully";
export const ERROR_OTP_MSG = "Error: OTP can't send...!";
export const OTP_SUCCESS = "OTP Matched";
export const INVALID_OTP = "Invalid OTP";
export const PASS_CHANGED = "Password Change successfully";
export const LINK_SEND = "Verification link sent successfully";
export const ASK = "Query Send";
export const subsMsg =
  " You do not have an active subscription. Please choose a subscription option for using Readmax Extension";
export const AWARD_SHOW_AWARD = "/admin/show/award";
export const AWARD_AWARD_ALL = "/admin/getall/awards";
export const AWARD_AWARD_DEL = "/admin/del/award";
export const INFO_DEL = "Information deleted successfully !";
export const TESTI_DEL = "Testimonial deleted successfully !";
export const UPDATE = "UPDATE";
export const AWARD_UPDATE = "admin/update/award";
export const AWARD_ADD_URL = "admin/add/award";
export const AWARD_CONTACT_URL = "admin/show/contact";
export const INFO_SHOW_URL = "/admin/show/info";
export const GET_SUBS_URL = "/show/user/subscription"; //show user subscription
export const RESEARCH_CONTENT = "admin/show/Res_content";
export const SUBS_CHECK_URL = "/subscription/check/status"; //show user subscription
export const EXIST = "already exist";
export const ALLREADY_ACTIVE = "already activated";
export const USERSERACH = "userSearchByToken";
export const USERQUERY = "/user/Ask";
export const GRANT = "Access";
export const ALLREDY_ACTIVETED = "Account already activated";
export const TOKEN_EXPIRED = "The token has expired";
export const SUBS_FOUND = "Subscription Found";
export const SEARCH_USER_URL = "user/search";
export const OTP_SEND_URL = "user/emailSend";
export const SessionExpire = "session expired";
export const User_Del = "User Deleted successfully";
export const Admin_Not_Block = "You can't block yourself";
export const Admin_Not_Del_Self = "You can't delete yourself";
export const Admin_Access_Remove = "You can't remove your admin access";
export const Admin_Access_Grant = "User is Admin Now";
export const User_AdminAccess_Remove = "Admin access removed";
export const Email_Required = "Email is required";
export const Username_Required = "Username is required";
export const Verification_Link_Send =
  "An account verification link has been sent to your email. Please verify the Link";
export const Invalid_Email = "Invalid Email..!";
export const Invalid_Username = "Invalid Username..!";
export const Invalid_Mobile = "Invalid Mobile Number";
export const Invalid_YoutubeLink = "Invalid Youtube Link";
export const Invalid_Title = "Invalid Title";
export const Mobile_Required = "Mobile number is required";
export const Password_Error = "Password must be minimum 6 characters long.";
export const Pass_Required = "Password is required";
export const Pass_Not_Match = "Password Not Match";
export const Password_Specification =
  "Password must contain atleast 1 Number,Special Character,capital and small alphabet.";
export const Research_Update = "Research Details Updated..!";
export const Research_Not_Update = "Research Details Can't Update..!";
export const Already_Exist = "Already Exist.";
export const Invalid_PDF = "Pdf Link is Invalid..!";
export const Invalid_Content = "Invalid Content";
export const Empty_Title_Err = "Title can't be empty";
export const Empty_Content_Err = "Content can't be empty";
export const Empty_PDF_Err = "PDF Link can't be empty";
export const Admin_Contact = "Please contact to admin";
export const User_Block_MSG = "You Are Blocked, Contact Admin";
export const User_Not_Found = "No user found";
export const Invalid_Input_FName = "Invalid Input for First Name";
export const Invalid_Input_LName = "Invalid Input for Last Name";
export const Award_Add = "An award has been added";
export const Contact_Msg = "Thanks for contacting Us.";
export const Msg_Empty = "Message field can't be empty";
export const Something_Error_MSG = "Something went wrong";
export const LogOut_MSG = "You have been successfully logged out";
export const BadRequest_MSG = "ERR_BAD_REQUEST";
export const Pass_Not_Update = "Error: Password can't be update..!";
export const Token_Ex = "Token Expired";

export const invalid_cred = "Invalid Credentials";
export const subsError =
  "Somtheing went wrong, please try again after some time.";
export const ParseLengthNote =
  "The extension is designed to proficiently parse text up to approximately 11,000 characters.";
export const userNotSubscribed = "Subscription not found!";
export const subscribedSuceessfully =
  "Congratulation..| You have now Subscribed";
export const errorOccuredWithPayment = "An Error occured with your payment";
export const paymentSuccess = "Payment successful!!";
export const lessQuantity = "Quantity should be greater than or equal to 3.";
export const inValidName = "Invalid Name";
export const installExtension =
  "Please enable or install the extension and refresh the page";
