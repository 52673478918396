import React from "react";
import { Image } from "react-bootstrap";
import googleplay from "../../assets/images/GooglePlay.png";
import appstore from "../../assets/images/AppStore.png";

export const GooglePlay = () => {
  const handleGooglePlayClick = () => {
    window.open(import.meta.env.VITE_GOOGLE_PLAY_STORE, "_blank");
  };

  return (
    <Image
      src={googleplay}
      alt="Google Play"
      onClick={handleGooglePlayClick}
      className="scale-up-animation"
    />
  );
};

export const AppStore = () => {
  const handleAppStoreClick = () => {
    const url = import.meta.env.VITE_APP_STORE;
    window.open(url, "_blank");
    ("");
  };

  return (
    <Image
      src={appstore}
      alt="App Store"
      onClick={handleAppStoreClick}
      className="scale-up-animation img-fluid"
    />
  );
};
